import { HttpEventType, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { tap } from "rxjs";
import { ToastService } from "../shared/toast.service";
import { ToastStatus } from "../models/ToastStatus";
import { AuthService } from "../shared/auth.service";

export function errorInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const toast = inject(ToastService);
  const authService = inject(AuthService);
  return next(request).pipe(tap(event => {
    if (event.type === HttpEventType.Response) {
      if (event.status !== 200 && event.status !== 201 && event.status !== 406) {
        toast.showToast(ToastStatus.Error, `Errore ${event.status}`, event.statusText);
      }
      if (event.status == 401) {
        authService.logout();
      }
    }
  }));
}
