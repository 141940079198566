import { Injectable } from '@angular/core';
import { ToastStatus } from '../models/ToastStatus';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public show: boolean = false;
  public title: string = '';
  public message: string = '';
  public type: ToastStatus = ToastStatus.Neutral;

  constructor() { }

  showToast(
    type: ToastStatus,
    title: string,
    message: string = '',
    duration: number = 5000
  ) {
    this.type = type;
    this.title = title;
    this.message = message;
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, duration);
  }
}
