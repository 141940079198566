import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const NoSpaces: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const answer = control.value;
  if (answer) {
    return answer.includes(' ') ? {hasSpaces: true} : null;
  } else {
    return null;
  }
}

export const Alphanumeric: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const answer = control.value;
  return answer && answer.match(/[^0-9a-zA-Z]/) ? {notAlphanumeric: true} : null;
}
