import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { CompleteComponent } from './pages/complete/complete.component';
import { TestComponent } from './pages/test/test.component';
import { AuthGuard } from './shared/auth.guard';

export const routes: Routes = [
  { path: '', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'complete', component: CompleteComponent, canActivate: [AuthGuard] },
];
