import { HttpErrorResponse, HttpEventType, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { LoaderService } from "../shared/loader.service";
import { catchError, of, tap } from "rxjs";

export function loadingInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const loaderService = inject(LoaderService);
  loaderService.setLoading(true);
  return next(request).pipe(
    tap(event => {
      if (event.type === HttpEventType.Response) {
        loaderService.setLoading(false);
      }
    }),
    catchError((error: HttpErrorResponse) => {
      loaderService.setLoading(false);
      return of(new HttpResponse({
        body: error.message,
        status: error.status,
        statusText: error.statusText
    }));
    })
  );
}
