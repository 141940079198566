<div class="content">
  @if (showChangePw) {
    <form [formGroup]="changePwForm" (ngSubmit)="changePw()">
      <h1>Cambio obbligatorio password</h1>

      <div class="input-group d-none">
        <input type="text" formControlName="user" autocomplete="username" readonly>
      </div>
      <div class="input-group">
        <div class="pw-wrapper">
          <input type="{{ showPw.new ? 'text' : 'password' }}" id="pw" class="form-control" formControlName="pw" autocomplete="new-password" placeholder="Nuova password">
          <button type="button" class="toggle-pw" (click)="showPw.new = !showPw.new">
            @if (showPw.new) {
              <img src="eye-open.svg" alt="Password visibile">
            } @else {
              <img src="eye-closed.svg" alt="Password visibile">
            }
          </button>
        </div>
        @if (changePwForm.controls.pw.errors) {
          @if (changePwForm.controls.pw.errors['invalidPw']) {
            <div class="error">
              La password deve:
              <ul>
                @if (changePwForm.controls.pw.errors['invalidPw'].length) { <li>contenere almeno 12 caratteri</li> }
                @if (changePwForm.controls.pw.errors['invalidPw'].upper) { <li>contenere almeno una lettera maiuscola</li> }
                @if (changePwForm.controls.pw.errors['invalidPw'].lower) { <li>contenere almeno una lettera minuscola</li> }
                @if (changePwForm.controls.pw.errors['invalidPw'].number) { <li>contenere almeno un numero</li> }
                @if (changePwForm.controls.pw.errors['invalidPw'].symbol) { <li>contenere almeno uno di questi simboli: ! &#64; # $ % ^ & *</li> }
              </ul>
            </div>
          }
        }
        @if (changePwForm.errors) {
          @if (changePwForm.errors['newOldEqual']) {
            <div class="error">La nuova password deve essere diversa da quella attuale</div>
          }
        }
      </div>

      <div class="input-group">
        <div class="pw-wrapper">
          <input type="{{ showPw.newConfirm ? 'text' : 'password' }}" id="pw-confirm" class="form-control" formControlName="pwConfirm" autocomplete="new-password" placeholder="Conferma password">
          <button type="button" class="toggle-pw" (click)="showPw.newConfirm = !showPw.newConfirm">
            @if (showPw.newConfirm) {
              <img src="eye-open.svg" alt="Password visibile">
            } @else {
              <img src="eye-closed.svg" alt="Password visibile">
            }
          </button>
        </div>
        @if (changePwForm.errors) {
          @if (changePwForm.errors['differentPw']) {
            <div class="error">Le due password non corrispondono</div>
          }
        }
      </div>

      <div class="input-group checkbox-group">
        <a (click)="openModal()">Leggi la privacy policy</a>
        <label for="privacy" class="{{ changePwForm.controls.privacy.disabled ? 'disabled' : '' }}">
          <input type="checkbox" id="privacy" formControlName="privacy">
          <span>Ho letto e compreso</span>
        </label>
        @if (changePwForm.controls.privacy.valid && changePwForm.controls.privacyRead.invalid) {
          <div class="error text-center">Leggi la privacy policy</div>
        }
      </div>

      <button type="submit" class="primary" [disabled]="changePwForm.invalid">Conferma cambio password</button>

      @if (changePwError) {
        <div class="error-form">Si è verificato un errore durante il cambio della password</div>
      }
    </form>
  } @else {
    <div class="motivational mb-4">Non ci sono limiti a ciò che puoi realizzare, <br>eccetto i limiti che ti poni...</div>

    <form [formGroup]="authForm" (ngSubmit)="login()">
      <h1>Inserisci le tue credenziali</h1>

      @if (pwChanged) {
        <div class="notice">Accedi con la nuova password</div>
      }

      <div class="input-group">
        <input type="text" id="user" class="form-control" formControlName="user" autocomplete="username" placeholder="Codice utente">
        @if (authForm.controls.user.touched && (authForm.controls.user.errors && authForm.controls.user.errors['invalidCF'] || authForm.controls.user.value && authForm.controls.user.value.length !== 16)) {
          <div class="error text-center show-not-focused">Inserire un codice fiscale con una struttura valida</div>
        }
      </div>

      <div class="input-group">
        <div class="pw-wrapper">
          <input type="{{ showPw.login ? 'text' : 'password' }}" id="pw" class="form-control" formControlName="pw" autocomplete="current-password" placeholder="Password">
          <button type="button" class="toggle-pw" (click)="showPw.login = !showPw.login">
            @if (showPw.login) {
              <img src="eye-open.svg" alt="Password visibile">
            } @else {
              <img src="eye-closed.svg" alt="Password visibile">
            }
          </button>
        </div>
      </div>

      <button type="submit" class="primary" [disabled]="authForm.invalid">Accedi alla piattaforma</button>

      @if (authError) {
        <div class="error-form">Credenziali errate</div>
      }
    </form>

    <div class="slogan text-uppercase mt-4">Mettiti alla prova!</div>
  }
</div>

@if (showPrivacyModal) {
  <div class="modal-privacy" (click)="closePrivacy($event)">
    <div class="modal-content">
      <button class="secondary close" (click)="showPrivacyModal = false">
        <img src="close.svg" alt="Chiudi">
      </button>
      <h2>Privacy policy</h2>
      @for (index of [1,2,3,4,5,6,7,8]; track index) {
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      }
      <button type="button" class="primary read-privacy" (click)="confirmReadPrivacy()">Ho letto la privacy policy</button>
    </div>
  </div>
}
