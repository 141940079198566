import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EqualPasswords, newPwDifferentFromOld, ValidPassword } from '../../shared/password.validator';
import { AuthService } from '../../shared/auth.service';
import { ValidCodiceFiscale } from '../../shared/user.validator';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  authForm = new FormGroup({
    user: new FormControl('', [
      Validators.required, 
      Validators.minLength(16), 
      Validators.maxLength(16), 
      ValidCodiceFiscale
    ]),
    pw: new FormControl('', Validators.required),
  });

  changePwForm = new FormGroup({
    user: new FormControl('', Validators.required),
    oldPw: new FormControl('', Validators.required),
    pw: new FormControl('', [Validators.required, ValidPassword]),
    pwConfirm: new FormControl('', [Validators.required, ValidPassword]),
    privacy: new FormControl({value: false, disabled: true}, Validators.requiredTrue),
    privacyRead: new FormControl(false, Validators.requiredTrue),
  }, {validators: [EqualPasswords, newPwDifferentFromOld]});

  showChangePw = false;
  authError = false;
  changePwError = false;
  pwChanged = false;
  showPrivacyModal = false;

  showPw = {
    login: false,
    new: false,
    newConfirm: false,
  };

  constructor(
    public router: Router,
    public authService: AuthService,
  ) {
    if (this.authService.isLogged()) {
      this.router.navigate(['']);
    }

    this.authForm.get("user")?.valueChanges.subscribe(value => {
      const _user = value ? value : '';
      this.authForm.get("user")?.setValue(_user.toUpperCase(), { emitEvent: false });
    });
  }

  login() {
    this.authError = false;
    this.authService.login(this.authForm.value.user!, this.authForm.value.pw!).subscribe((result: any) => {
      if (result.success) {
        if (result.completed) {
          this.router.navigate(['complete']);
        } else {
          if (result.change_pw) {
            this.showChangePw = true;
            this.changePwForm.patchValue({
              user: this.authForm.value.user,
              oldPw: this.authForm.value.pw,
            });
          } else {
            this.router.navigate(['']);
          }
        }
      } else {
        this.authError = true;
      }
    });
  }

  changePw() {
    this.changePwError = false;
    this.authService.changePw(
      this.changePwForm.value.user!, 
      this.changePwForm.value.oldPw!, 
      this.changePwForm.value.pw!
    ).subscribe((result: any) => {
      if (result.passwordChanged) {
        this.showChangePw = false;
        this.pwChanged = true;
        this.changePwForm.reset();
        this.authForm.reset();
        this.showPw.login = false;
      } else {
        this.changePwError = true;
      }
    });
  }

  openModal() {
    this.showPrivacyModal = true;
  }

  confirmReadPrivacy() {
    this.changePwForm.patchValue({privacyRead: true});
    this.changePwForm.get('privacy')?.enable();
    this.showPrivacyModal = false;
  }

  closePrivacy(event: any) {
    if (event.target.classList.contains('modal-privacy')) {
      this.showPrivacyModal = false;
    }
  }
}
