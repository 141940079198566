import { Component } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { Challenge } from '../../models/Challenge';
import { ChallengeService } from '../../shared/challenge.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complete',
  standalone: true,
  imports: [],
  templateUrl: './complete.component.html',
  styleUrl: './complete.component.scss'
})
export class CompleteComponent {
  stepsArray = [...Array(3).keys()];

  challenge: Challenge | null;

  constructor(
    public router: Router,
    public authService: AuthService,
    public challengeService: ChallengeService,
  ) {
    this.challenge = challengeService.getChallenge();

    this.challengeService.getChallengeRemote().subscribe((data: any) => {
      this.challenge = {
        challenge_id: data.challenge_id,
        challenge: data.challenge,
        figure: data.figure,
        completed: data.completed,
      };
      this.challengeService.storeChallenge({
        challenge_id: data.challenge_id,
        challenge: data.challenge,
        figure: data.figure,
        completed: data.completed,
      });

      if (!this.challenge.completed) {
        this.router.navigate(['']);
      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
