import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { ChallengeService } from "./challenge.service";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor (
    private http: HttpClient,
    private router: Router,
    private challengeService: ChallengeService,
  ) { }

  login(username: string, password: string) {
    var subject = new Subject<any>();
    this.http.post(
      `${environment.apiUrl}auth/login`, 
      {puid: username, password: password, cacheFigure: false}, 
      {observe: 'response', responseType: 'json'}
    ).subscribe((response) => {
      if (response.status == 406) {
        subject.next({
          success: true,
          change_pw: true,
          completed: (response.body as any).completed,
        });
      } else {
        const body = response.body as any;
        if (body.access_token) {
          this.setToken(body.access_token);
          this.challengeService.storeChallenge({
            challenge_id: body.challenge_id,
            challenge: body.challenge,
            figure: body.figure,
            completed: body.completed,
          });
        }

        subject.next({
          success: body.access_token ? true : false,
          change_pw: false,
          completed: body.completed,
        });
      }
    });
    return subject.asObservable();
  }

  changePw(username: string, old_password: string, new_password: string) {
    return this.http.post(`${environment.apiUrl}auth/change-password`, {puid: username, currentPassword: old_password, newPassword: new_password});
  }

  logout() {
    this.setToken(null);
    this.challengeService.storeChallenge(null);
    this.router.navigate(['login']);
  }

  isLogged() {
    const token = localStorage.getItem('ct-token');
    return token !== null && token !== '';
  }

  setToken(token: string | null) {
    token ? localStorage.setItem('ct-token', token) : localStorage.removeItem('ct-token');
  }
  getToken() {
    return localStorage.getItem('ct-token');
  }
}
