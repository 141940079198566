import { Component } from '@angular/core';
import { ToastService } from '../../shared/toast.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [
    trigger('showHide', [
      transition(':enter', [style({right: '-100%'}), animate('1500ms ease-in-out', style({right: '20px'}))]),
      transition(':leave', [animate('1500ms ease-in-out', style({right: '-100%'}))]),
    ])
  ]
})
export class ToastComponent {
  constructor(
    public toastService: ToastService,
  ) { }
}
