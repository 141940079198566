import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const ValidPassword: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const pw = control.value;
  if (pw) {
    const koLength = pw.length < 12;
    const koUpper = !pw.match(/[A-Z]/);
    const koLower = !pw.match(/[a-z]/);
    const koNumber = !pw.match(/[0-9]/);
    const koSymbol = !pw.match(/[!@#$%^&*]/);
    const invalidPw = {
      length: koLength,
      upper: koUpper,
      lower: koLower,
      number: koNumber,
      symbol: koSymbol,
    }
    return pw !== '' && (koLength || koUpper || koLower || koNumber || koSymbol) ? {invalidPw: invalidPw} : null;
  } else {
    return null;
  }
}

export const EqualPasswords: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const pw = control.get('pw')?.value;
  const pwConfirm = control.get('pwConfirm')?.value;
  return pw && pwConfirm && pw !== '' && pwConfirm !== '' && pw !== pwConfirm ? {differentPw: true} : null;
}

export const newPwDifferentFromOld: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const pw = control.get('pw')?.value;
  const oldPw = control.get('oldPw')?.value;
  return pw && oldPw && pw !== '' && oldPw !== '' && pw === oldPw ? {newOldEqual: true} : null;
}
