@if (challenge) {
<header>
  <div class="row">
    <div class="col-6 text-end justify-content-end info">
      <a href="informativa_semplificata.pdf" target="_blank" download>
        Privacy <span class="hide-mobile">policy</span>
        <img src="download.svg" alt="Download privacy policy">
      </a> | 
      <button class="btn" (click)="logout()">Logout</button>
    </div>
    <div class="col-6 info">
      <span>Challenge <b>#{{ challenge.challenge_id }}</b></span>
    </div>
  </div>
</header>
<div class="container">
  <div class="row">
    <div class="col-lg-2">
      <div class="progress-test">
        @for (step of stepsArray; track step) {
          @if (step > 0) {
            <div class="line" [class.complete]="challenge.challenge_id > step"></div>
          }
          <div class="step" [class.active]="challenge.challenge_id == step + 1" [class.complete]="challenge.challenge_id > step + 1 || (challenge.challenge_id - 1 == step && passed)">{{ step + 1 }}</div>
        }
      </div>
    </div>
    <div class="col-lg-8">
      <div class="challenge">
        <h1>
          @switch (challenge.challenge_id) {
            @case (1) {
              Trova il codice fantasma
            }
            @case (2) {
              Il codice segreto nel labirinto binario
            }
            @case (3) {
              Segreti nell’assembler: il codice invisibile
            }
          }
        </h1>
        <p class="question">{{ challenge.challenge }}</p>
        <div class="img-wrapper">
          <img class="challenge-img" src="data:image/jpeg;base64,{{ challenge.figure }}" (click)="showImgFullscreen = true;">
          <a href="data:image/jpeg;base64,{{ challenge.figure }}" class="download" target="_blank" download="challenge-{{ challenge.challenge_id }}.jpeg">
            <img src="download.svg" alt="Scarica immagine">
            Scarica immagine
          </a>
        </div>
        <form [formGroup]="answerForm" (ngSubmit)="submitAnswer()">
          @if (!passed) {
            <div class="input-group">
              <input type="text" class="form-control" id="answer" formControlName="answer" placeholder="Risposta" autocomplete="off">
              @if (answerForm.controls.answer.errors) {
                @if (answerForm.controls.answer.errors['hasSpaces']) {
                  <div class="error">La risposta non può contenere spazi</div>
                }
                @if (answerForm.controls.answer.errors['notAlphanumeric']) {
                  <div class="error">La risposta può contenere soltanto caratteri alfanumerici non accentati</div>
                }
              }
            </div>
          }

          @if (passed) {
            <div class="result success">
              <span>Hai completato con successo questa challenge</span><br>
              @if (challenge.challenge_id === 3) {
                <a [routerLink]="['/complete']" class="primary">Concludi</a>
              } @else {
                <div class="btn-group">
                  <button type="button" class="secondary" (click)="showConfirmStop = true">Interrompi il test</button>
                  <button type="button" class="primary" (click)="goNextChallenge()">Prosegui</button>
                </div>
              }
            </div>
          } @else if (submitted && !passed) {
            <div class="result error">
              <span>La risposta non è corretta</span>
            </div>
          }

          @if (!passed) {
            <div class="btn-group">
              <button type="button" class="secondary reset" (click)="answerForm.reset()" [disabled]="!answerForm.controls.answer.value">Cancella</button>
              <button type="submit" class="primary" [disabled]="answerForm.invalid">Invia la risposta</button>
            </div>
          }
        </form>
      </div>
    </div>
  </div>
</div>

@if (showImgFullscreen) {
  <div class="fullscreen" (click)="closeFullscreen($event)">
    <button class="secondary close" (click)="showImgFullscreen = false">
      <img src="close.svg" alt="Chiudi">
    </button>
    <img src="data:image/jpeg;base64,{{ challenge.figure }}" class="challenge-img">
  </div>
}

@if (showConfirmStop) {
  <div class="fullscreen" (click)="closeFullscreen($event)">
    <div class="content">
      <button class="secondary close" (click)="showConfirmStop = false">
        <img src="close.svg" alt="Chiudi">
      </button>
      <p>Cliccando il pulsante qui sotto, verrai disconnesso. Potrai proseguire in un secondo momento effettuando nuovamente il login.</p>
      <button type="button" class="primary" (click)="logout()">Interrompi il test</button>
    </div>
  </div>
}
}
