<header>
  <div class="row">
    <div class="col-12 text-end justify-content-end info">
      <a href="informativa_semplificata.pdf" target="_blank" download>
        Privacy <span class="hide-mobile">policy</span>
        <img src="download.svg" alt="Download privacy policy">
      </a> | 
      <button class="btn" (click)="logout()">Logout</button>
    </div>
  </div>
</header>

<div class="content">
  <div class="complete">
    <h1>Congratulazioni</h1>
    <p>Hai completato correttamente tutte le challenge.</p>
    <div class="progress-test">
      @for (step of stepsArray; track step) {
        @if (step > 0) {
          <div class="line"></div>
        }
        <div class="step"></div>
      }
    </div>
  </div>
</div>
