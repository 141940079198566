import { Component } from '@angular/core';
import { Challenge } from '../../models/Challenge';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Alphanumeric, NoSpaces } from '../../shared/answer.validator';
import { AuthService } from '../../shared/auth.service';
import { ChallengeService } from '../../shared/challenge.service';

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {
  challenge: Challenge | null;

  stepsArray = [...Array(3).keys()];

  showImgFullscreen = false;
  showConfirmStop = false;

  answerForm = new FormGroup({
    answer: new FormControl('', [Validators.required, NoSpaces, Alphanumeric]),
  });

  submitted = false;
  passed = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public challengeService: ChallengeService,
  ) {
    this.challenge = this.challengeService.getChallenge();
    if (this.challenge && this.challenge.completed) {
      this.router.navigate(['complete']);
    }

    this.challengeService.getChallengeRemote().subscribe((data: any) => {
      this.challenge = {
        challenge_id: data.challenge_id,
        challenge: data.challenge,
        figure: data.figure,
        completed: data.completed,
      };
      this.challengeService.storeChallenge({
        challenge_id: data.challenge_id,
        challenge: data.challenge,
        figure: data.figure,
        completed: data.completed,
      });

      if (this.challenge && this.challenge.completed) {
        this.router.navigate(['complete']);
      }
    });

    this.answerForm.get("answer")?.valueChanges.subscribe(value => {
      this.submitted = false;
    });
  }

  submitAnswer() {
    this.challengeService.submitChallenge(this.answerForm.value.answer!).subscribe(data => {
      this.submitted = true;
      if (data.correct) {
        this.passed = true;
        this.challengeService.storeChallenge({
          challenge_id: data.challenge_id,
          challenge: data.challenge,
          figure: data.figure,
          completed: data.completed,
        });
      } else {
        this.passed = false;
      }
    });
  }

  goNextChallenge() {
    this.answerForm.reset();
    this.submitted = false;
    this.passed = false;
    this.challenge = this.challengeService.getChallenge();
    if (!this.challenge) {
      this.authService.logout();
    } else if (this.challenge.completed) {
      this.router.navigate(['complete']);
    } else {
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    }
  }

  logout() {
    this.authService.logout();
  }

  closeFullscreen(event: any) {
    if (event.target.classList.contains('fullscreen')) {
      this.showConfirmStop = false;
      this.showImgFullscreen = false;
    }
  }
}
