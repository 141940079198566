import { Injectable } from '@angular/core';
import { Challenge } from '../models/Challenge';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ChallengeService {
    constructor(
        private http: HttpClient
    ) { }

    public getChallengeRemote() {
        return this.http.get<any[]>(`${environment.apiUrl}challenges/current`);
    }
    public submitChallenge(answer: string): Observable<any> {
        return this.http.post<any[]>(`${environment.apiUrl}challenges/submit`, {answer: answer, cacheFigure: true});
    }

    public storeChallenge(challenge: Challenge | null) {
        challenge ? localStorage.setItem('ct-challenge', btoa(JSON.stringify(challenge))) : localStorage.removeItem('ct-challenge');
    }
    public getChallenge() {
        const _challenge = localStorage.getItem('ct-challenge')
        return _challenge ? JSON.parse(atob(_challenge)) : null;
    }
}
