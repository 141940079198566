import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { AuthService } from "../shared/auth.service";
import { inject } from "@angular/core";

export function authInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const token = inject(AuthService).getToken();
  if (token && token !== '') {
    request = request.clone({
      setHeaders: {'Authorization': `Bearer ${token}`, 'x-access-token': `${token}`}
    });
  }
  return next(request);
}
